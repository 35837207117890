@import '../../assets/styles/colors';


.addCheckpointForm {
    margin-bottom: 200px;
}
.addCheckpointForm__submit {
    background-color: $wtch-darkGray;
    border: none;
    color: $wtch-white;
    font-weight: 700;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 10px 30px;
    transform: skewY(-5deg);
    transition: 0.1s ease-in-out;

    &:disabled{
        opacity: 0.3;
        transform: skewY(0);
    }

    &:hover:not(:disabled) {
        cursor: pointer;
        background-color: $wtch-darkBlue--secondary;
        border-radius: 3px;
        transform: skewY(0);
        transition: 0.1s ease-in-out;
    }
}

label {
    font-weight: 700;
    text-align: left;

    input, select {
        width: 90%;
        margin: 10px 5%;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $wtch-darkGray;

        &:focus {
            outline: none;
        }
    }

    input {
        padding: 10px 5px;

        
    }
}