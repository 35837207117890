@import '../../assets/styles/colors';

.checkpointRow {
    flex-basis: 23%;
    margin: 15px 2%;
    border-radius: 10px;
    padding: 10px;
    max-width: 370px;
    box-shadow: 0 0 10px darken($wtch-white, 15);
    background-color: darken($wtch-white, 5);

    &_error {
        box-shadow: 0 0 10px rgba(darken($wtch-pink, 10), 0.4);
        background-color: darken($wtch-pink, 3);
    }
    &_success {
        box-shadow: 0 0 5px rgba(darken($wtch-green, 10), 0.8);
        background-color: darken($wtch-green, 3);
    }

    &__name {
        display: block;
        text-align: center;
    }
    &__thumbnail {
        margin: 5px auto;
        width: auto;
        max-width: 100%;
    }
    &__lastCheck {
        opacity: 0.5;
        font-size: 0.8em;
    }
    &__latestValue {
        font-weight: 700;
    }


    &__checkNowButton {
        background-color: $wtch-green;
        color: $wtch-white;
    }
    &__deleteButton {
        color: red;
        font-weight: 700;
        border: 1px solid $wtch-darkGray;
        background-color: transparent;
        padding: 10px;
    }
}