@import './assets/styles/inputs';
@import './assets/styles/buttons';

.watchaApp {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.watchaApp-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .watchaApp-logo {
    animation: watchaApp-logo-float infinite 3s ease-in-out;
    max-width: 300px;
    height: auto;
    margin: 30px auto;
  }
}

.watchaApp-link {
  color: rgb(112, 76, 182);
}

@keyframes watchaApp-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px)
  }
  100% {
    transform: translateY(0px)
  }
}
